import * as React from "react";
import {
  LodgingForm,
  FormOptions,
  DateField,
  TravelersField,
  FormActionProvider,
  FormAction,
  TravelersFieldTriggerType,
  SubmitButton,
  ReferrerContext,
} from "@shared-ui/lodging-form";
import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";

import { useLocalization } from "@shared-ui/localization-context";
import { useDialog } from "@shared-ui/dialog-context";
import { PropertyStickyBookBar } from "@shared-ui/lodging-property-offers";
import { PropertyAvailability } from "@shared-ui/retail-lodging-property-availability";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

import { ComponentProps } from "typings/flexFramework/FlexComponent";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";

import { useDateFieldData } from "./DateFieldData";
import { useTravelersFieldData } from "./TravelersFieldData";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { useRefContext } from "@shared-ui/ref-context";
import { useStickyElement } from "components/shared/StackedSticky/StackedSticky";
import { useLocation } from "react-router-dom";

const WrappedLodgingForm = PropertyBexApiWrapper(LodgingForm);
const WrappedStickyBookBar = PropertyBexApiWrapper(PropertyStickyBookBar);

const formData: FormOptions["data"] = {
  id: "pdp-search-form",
  method: "GET",
  action: "/Hotel-Search",
};

interface ActionProps {
  onChange: (field: string) => void;
}

interface MobileFormProps extends ComponentProps {
  showCTAButton?: boolean;
  hidePrice?: boolean;
}

interface DesktopFormProps extends ComponentProps {
  autoOpenCalendar?: boolean;
  enableWeekdayFormatting?: boolean;
}

interface SingleOfferForm extends ComponentProps {
  enableWeekdayFormatting?: boolean;
  maxDateRange?: number;
}

const ActionProvider: React.FC<ActionProps> = ({ onChange, children }) => {
  const dispatch = React.useMemo(
    () => (action: FormAction) => {
      switch (action.type) {
        case "change":
          onChange(action.field);
          break;
      }
    },
    [onChange]
  );

  return <FormActionProvider value={dispatch}>{children}</FormActionProvider>;
};

export const MobileForm: React.FC<MobileFormProps> = ({ context, children, showCTAButton, hidePrice }) => {
  const { formatText } = useLocalization();
  const dateFieldData = useDateFieldData();
  const travelersFieldData = useTravelersFieldData();
  const [, datePickerDialogActions] = useDialog("datepicker-start");

  const { registerTarget } = useRefContext();

  // registering with the ref-context so the shared-ui sticky bar can find it.
  const formRef = registerTarget<HTMLFormElement>("pdp-search-form");
  const startDateRef = registerTarget<HTMLDivElement>("startDate");
  const endDateRef = React.useRef<HTMLElement | null>(null);
  const [autoOpenCalendar, setAutoOpenCalendar] = React.useState(false);

  const dateFieldProps = {
    fieldName: "dates",
    data: dateFieldData,
    startDateRef,
    endDateRef,
  };

  const travelersFieldProps = {
    fieldName: "travelers",
    data: travelersFieldData,
    closeOnSubmit: false,
    trigger: TravelersFieldTriggerType.INPUT,
  };

  const onChange = (field: string) => {
    if (field === "travelers") {
      datePickerDialogActions.openDialog();
      setAutoOpenCalendar(true);
    }

    // We cannot test the submission because it's not implemented by ReactDOM
    /* istanbul ignore next */
    if (field === "dates") {
      setTimeout(() => formRef?.current?.submit(), 1);
    }
  };

  return (
    <ReferrerContextProvider>
      <ActionProvider onChange={onChange}>
        <WrappedLodgingForm context={context} data={formData} formRef={formRef}>
          <UitkSpacing padding={{ blockend: "four" }}>
            <UitkLayoutFlex space="two" wrap="wrap">
              <UitkLayoutFlexItem grow={2}>
                <div>
                  <DateField {...dateFieldProps} closeOnSubmit={false} autoOpenCalendar={autoOpenCalendar} />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem minWidth="full_width">
                <div>
                  <TravelersField {...travelersFieldProps} />
                </div>
              </UitkLayoutFlexItem>
              {showCTAButton && (
                <>
                  <UitkLayoutFlexItem minWidth="full_width">
                    <div>
                      <SubmitButton
                        fieldName="submit"
                        data={{
                          __typename: "LodgingFormSubmitButton",
                          label: formatText("hotels.checkAvailability"),
                        }}
                      />
                    </div>
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem minWidth="full_width">
                    <WrappedStickyBookBar
                      context={context}
                      clickTargetRef="startDate"
                      scrollSectionRef="SearchForm"
                      hideSectionRef="SearchForm"
                      hidePrice={hidePrice}
                    />
                  </UitkLayoutFlexItem>
                </>
              )}
              <HiddenFields context={context} />
              {children}
            </UitkLayoutFlex>
          </UitkSpacing>
        </WrappedLodgingForm>
      </ActionProvider>
    </ReferrerContextProvider>
  );
};

export const DesktopForm: React.FC<DesktopFormProps> = ({
  context,
  children,
  autoOpenCalendar: autoOpenCalendarProp,
  enableWeekdayFormatting,
}) => {
  const { formatText } = useLocalization();
  const dateFieldData = useDateFieldData();
  const travelersFieldData = useTravelersFieldData();
  const { registerTarget } = useRefContext();
  const startDateRef = registerTarget<HTMLDivElement>("startDate");
  const endDateRef = React.useRef<HTMLElement | null>(null);

  const [autoOpenCalendar, setAutoOpenCalendar] = React.useState(autoOpenCalendarProp);
  const { isSticky } = useStickyElement();
  React.useEffect(() => {
    if (isSticky) {
      setAutoOpenCalendar(false);
    }
  }, [isSticky]);

  const dateFieldProps = {
    fieldName: "dates",
    data: dateFieldData,
    startDateRef,
    endDateRef,
  };

  const travelersFieldProps = {
    fieldName: "travelers",
    data: travelersFieldData,
    closeOnSubmit: true,
    trigger: TravelersFieldTriggerType.INPUT,
  };

  return (
    <ReferrerContextProvider>
      <WrappedLodgingForm context={context} data={formData}>
        <UitkSpacing padding={{ blockstart: "two", blockend: "four" }}>
          <UitkLayoutFlex space="two" wrap="wrap">
            <UitkLayoutFlexItem grow={2}>
              <div>
                <DateField
                  {...dateFieldProps}
                  autoOpenCalendar={autoOpenCalendar}
                  mediumDateFormatProp={enableWeekdayFormatting ? "MMMEd" : "MMMd"}
                />
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem minWidth="third_width">
              <div>
                <TravelersField {...travelersFieldProps} />
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem grow={1}>
              <div>
                <SubmitButton
                  fieldName="submit"
                  data={{
                    __typename: "LodgingFormSubmitButton",
                    label: formatText("hotels.checkAvailability"),
                  }}
                />
              </div>
            </UitkLayoutFlexItem>
            <HiddenFields context={context} />
            {children}
          </UitkLayoutFlex>
        </UitkSpacing>
      </WrappedLodgingForm>
    </ReferrerContextProvider>
  );
};

export const SingleOfferForm: React.FC<SingleOfferForm> = ({
  context,
  enableWeekdayFormatting,
  maxDateRange,
  children,
}) => {
  const { formatText, formatDateString } = useLocalization();
  const isVrbo = context?.site?.brand === "vrbo";
  const dateFieldData = useDateFieldData(isVrbo, maxDateRange);
  const travelersFieldData = useTravelersFieldData(isVrbo);
  const { registerTarget } = useRefContext();
  const startDateRef = registerTarget<HTMLDivElement>("startDate");
  const endDateRef = React.useRef<HTMLElement | null>(null);
  const dateFieldProps = {
    fieldName: "dates",
    data: dateFieldData,
    startDateRef,
    endDateRef,
  };

  const travelersFieldProps = {
    fieldName: "travelers",
    data: travelersFieldData,
    closeOnSubmit: true,
    trigger: TravelersFieldTriggerType.INPUT,
  };

  const singleOfferFormData: FormOptions["data"] = {
    id: formData.id,
    method: formData.method,
    action: getAction(),
  };

  const singleOfferFormDataAvailabilityVariant: FormOptions["data"] = {
    id: formData.id,
    method: formData.method,
    action: getActionAvailabilityVariant(),
  };

  function getAction() {
    if (isVrbo) {
      const location = useLocation();
      const regex = /(\/[a-z]{2}-[a-z]{2}\/)/;
      const pathName = location?.pathname.match(regex)?.[1] || "";
      return pathName === "" ? "/search" : `${pathName}search`;
    }
    return "/Hotel-Search";
  }

  function getActionAvailabilityVariant() {
    if (isVrbo) {
      const location = useLocation();
      return location?.pathname;
    }
    return "/Hotel-Search";
  }

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const onSubmitCB = (start: Date | null, end: Date | null): void => {
    if (start) {
      setStartDate(formatDateString(start, { raw: "yyyy-MM-dd" }));
    }
    if (end) {
      setEndDate(formatDateString(end, { raw: "yyyy-MM-dd" }));
    }
  };

  return (
    <Experiment name={"Availability_calendar_to_Vrbo_Flex_Dateless_PDP"}>
      <ExperimentControl>
        <ReferrerContextProvider>
          <WrappedLodgingForm context={context} data={singleOfferFormData}>
            <UitkLayoutFlex space="three" wrap="wrap">
              <UitkLayoutFlexItem minWidth="full_width">
                <div>
                  <DateField {...dateFieldProps} mediumDateFormatProp={enableWeekdayFormatting ? "MMMEd" : "MMMd"} />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem minWidth="full_width">
                <div>
                  <TravelersField {...travelersFieldProps} />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem minWidth="full_width">
                <div>
                  <SubmitButton
                    fieldName="submit"
                    data={{
                      __typename: "LodgingFormSubmitButton",
                      label: formatText("hotels.checkAvailability"),
                    }}
                  />
                </div>
              </UitkLayoutFlexItem>
              <HiddenFields context={context} />
              {children}
            </UitkLayoutFlex>
          </WrappedLodgingForm>
        </ReferrerContextProvider>
      </ExperimentControl>
      <ExperimentVariant bucket={1}>
        <ReferrerContextProvider>
          <WrappedLodgingForm context={context} data={singleOfferFormDataAvailabilityVariant}>
            <UitkLayoutFlex space="three" wrap="wrap">
              <UitkLayoutFlexItem minWidth="full_width">
                <div>
                  <PropertyAvailability
                    startDateRef={startDateRef}
                    endDateRef={endDateRef}
                    inputs={{ eid: String(context?.searchContext?.location.id), dateRange: null }}
                    onSubmit={onSubmitCB}
                    skipSsr
                  />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem minWidth="full_width">
                <div>
                  <TravelersField {...travelersFieldProps} />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem minWidth="full_width">
                <div>
                  <SubmitButton
                    fieldName="submit"
                    data={{
                      __typename: "LodgingFormSubmitButton",
                      label: formatText("hotels.checkAvailability"),
                    }}
                  />
                </div>
              </UitkLayoutFlexItem>
              <HiddenFieldsWithDates context={context} startDate={startDate} endDate={endDate} />
              {children}
            </UitkLayoutFlex>
          </WrappedLodgingForm>
        </ReferrerContextProvider>
      </ExperimentVariant>
    </Experiment>
  );
};

const HiddenFieldsWithDates: React.FC<{ context: ExtendedContextStore; startDate: string; endDate: string }> = ({
  context,
  startDate,
  endDate,
}) => {
  const selectedId = String(context.searchContext.location.id);
  const regionId = String(context.searchContext.location.parent?.id);
  const shouldIncludeStartDate = startDate.length > 0;
  const shouldIncludeEndDate = endDate.length > 0;
  return (
    <>
      {shouldIncludeStartDate && <input type="hidden" name="startDate" value={startDate} />}
      {shouldIncludeEndDate && <input type="hidden" name="endDate" value={endDate} />}
      <input type="hidden" name="selected" value={selectedId} />
      <input type="hidden" name="regionId" value={regionId} />
    </>
  );
};

const HiddenFields: React.FC<{ context: ExtendedContextStore }> = ({ context }) => {
  const selectedId = String(context.searchContext.location.id);
  const regionId = String(context.searchContext.location.parent?.id);
  return (
    <>
      <input type="hidden" name="selected" value={selectedId} />
      <input type="hidden" name="regionId" value={regionId} />
    </>
  );
};

const ReferrerContextProvider: React.FC = ({ children }) => {
  const referrerContext = React.useMemo(() => ({ referrer: { pagePrefix: "HOT.HIS" } }), []);

  return <ReferrerContext.Provider value={referrerContext}>{children}</ReferrerContext.Provider>;
};
ReferrerContextProvider.displayName = "ReferrerContextProvider";
